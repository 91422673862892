var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    attrs: {
      "max-width": "600"
    }
  }, [_c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.params._id ? _c('span', [_vm._v("리뷰 상세")]) : _c('span', [_vm._v("리뷰 작성")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("작성자")]), _c('v-spacer'), _c('span', {
    staticClass: "body-2"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.review.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.review.writer, "name", $$v);
      },
      expression: "review.writer.name"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("작성일자")]), _c('v-spacer'), _c('span', {
    staticClass: "body-2"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs(_vm.review.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")])], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("조회수")]), _c('v-spacer'), _c('span', {
    staticClass: "body-2"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.review.viewCount,
      callback: function ($$v) {
        _vm.$set(_vm.review, "viewCount", $$v);
      },
      expression: "review.viewCount"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("숨김/보임")]), _c('v-spacer'), _c('span', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.review, "onDisplay", $$v);
      },
      expression: "review.onDisplay"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("제목")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-text-field', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.review.subject,
      callback: function ($$v) {
        _vm.$set(_vm.review, "subject", $$v);
      },
      expression: "review.subject"
    }
  })], 1), _c('v-divider'), _c('v-card-title', {
    staticClass: "align-center"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("내용")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.content,
      callback: function ($$v) {
        _vm.$set(_vm.review, "content", $$v);
      },
      expression: "review.content"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }