<template>
    <v-responsive max-width="600">

        <v-row class="mt-4 px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="$route.params._id">리뷰 상세</span>
                <span v-else>리뷰 작성</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-3">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">작성자</span>
                <v-spacer />
                <span class="body-2">
                    <v-text-field v-model="review.writer.name" dense hide-details outlined />
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">작성일자</span>
                <v-spacer />
                <span class="body-2">
                    {{ $dayjs(review.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">조회수</span>
                <v-spacer />
                <span class="body-2">
                    <v-text-field v-model="review.viewCount" dense hide-details outlined />
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">숨김/보임</span>
                <v-spacer />
                <span>
                    <v-switch v-model="review.onDisplay" outlined dense hide-details class="mt-0"/>
                </span>
            </v-card-title>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">제목</span>
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="review.subject" dense hide-details outlined style="width: 100%" />
            </v-card-text>
            <v-divider />
            <v-card-title class="align-center">
                <span class="subtitle-2">내용</span>
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-textarea v-model="review.content" outlined dense hide-details />
            </v-card-text>
        </v-card>
        
        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save()">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </v-responsive>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";
import VFileBtn from '@/components/plugins/vuetify/v-file-btn.vue';

export default {
    components: {
        VPasswordField,
        VFileBtn
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            review: {
                subject: null,
                content: null,
                writer: {
                    name: null,
                },  
                onDisplay: true,
                viewCount: 0
            },
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._id){
                let { review } = await api.console.easyleg.reviews2.get({ _id: this.$route.params._id });
                this.review = review;
            }
            else{
                let { summary } = await api.console.easyleg.reviews2.gets({ headers: { limit: 1 } });
                this.review.no = summary.totalCount + 1;
            }
        },

        async save(){
            let { review } = await api.console.easyleg.reviews2[this.review._id ? "put" : "post"](this.review);

            alert("저장되었습니다");
            this.$router.push(`/console/easyleg/reviews2`);
        },

        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    }
}
</script>

<style scoped>
</style>